// import './App.css';
import '../../App.css'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function RollingCommission() {
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details

    return (
        <div className='grid grid-cols-12'>
            <div className='col-span-12 rounded-b-[.3rem] !bg-[#ffffff] !text-[#23282c] p-4'>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Fancy
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span> {userInfo?.rolling_commission_fancy}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Matka
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_matka}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Lottery
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>0</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Casino
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_casino}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Binary
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_binary}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Virtual
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_virtualsport}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Sportbook
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_sportbook}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Bookmaker
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_bookmaker}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default RollingCommission;
