// import './App.css';
import '../../App.css'
import { useState, useEffect } from 'react';
import { Table } from 'antd';
import axios from 'axios';
import Appconfig from '../../config/config'
import { useDispatch,useSelector } from 'react-redux';

function ActivityLog() {
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details

    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
        "user_type": userInfo && userInfo?.user_type,
    })
 
    let locale = {
        emptyText: 'No data!',
    };
    const columns = [
        {
            title: 'Login Date & Time',
            dataIndex: 'login-date-time',
            width: '23%',
            render: (_, record) => (
                <span className='font-extrabold text-[#212529] text-[0.813rem] '>{new Date(record?.createdAt).toLocaleString()}</span>
            )
        },
        {
            title: 'Login Status',
            dataIndex: 'login-status',
            // render: (name) => `${name.first} ${name.last}`,
            render: (_, record) => (
                <span className={`font-extrabold text-[0.813rem] ${record?.status == "Open" ? 'text-[#228b22]' : 'text-[#dc3545]'}`}>{record?.status == "Open" ? 'Login Successful' : 'Login Failed'}</span>
            ),
            width: '20%'
        },
        {
            title: 'IP Address',
            dataIndex: 'ip-address',
            render: (_, record) => (
                <span className='font-extrabold text-[#212529] text-[0.813rem] '>{record?.ip_address}</span>
            ),
            width: '18%'

        },
        {
            title: 'ISP',
            dataIndex: 'isp',
            render: (_, record) => (
                <span className='font-extrabold text-[#212529] text-[0.813rem] '>{record?.all_info[0]?.isp}</span>
            ),
            width: '14%'
        },
        {
            title: 'City/State/Country',
            dataIndex: 'address',
            render: (_, record) => (
                <span className='font-extrabold text-[#212529] text-[0.813rem] '>{record?.all_info[0]?.city + ' / ' + record?.all_info[0]?.regionName + ' / ' + record?.all_info[0]?.country}</span>
            ),
        },
    ];
  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        activeLog();
    }, [])


    const activeLog = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/usersOnline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    setData(response.data.resultData);

                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }


    return (
        <>
            <div className='hidden lg:block mt-[6px] !mb-4 text-[0.75rem] relative flex flex-col min-w-[0] [word-wrap:break-word]   rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Activity Log</span>
                                </div>
                                <div className='p-[1.25rem] flex-auto '>
                                    <Table
                                        bordered
                                        locale={locale}
                                        columns={columns}
                                        className='activity-log profit-loss'
                                        dataSource={data}
                                        // pagination={tableParams.pagination}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='lg:hidden relative top-[56px]  text-[0.75rem]  flex flex-col min-w-[0] [word-wrap:break-word] rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Activity Log</span>
                                </div>
                                <div className='p-[1.25rem] flex-auto overflow-auto '>
                                    <Table
                                        locale={locale}
                                        columns={columns}
                                        bordered
                                        className=' profit-loss'
                                        dataSource={data}
                                        // pagination={tableParams.pagination}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ActivityLog;
