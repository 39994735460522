import '../../App.css'
import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { signal } from "@preact/signals-react";
import axios from 'axios';
import Appconfig from '../../config/config'
import TableRow from '../../components/TableRow';
import { useSelector, useDispatch } from 'react-redux';

export const eventId = signal("");
function MultiMarket() {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details

    const [events, setEvents] = useState([])
    function getMypins() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });

            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}eventsDashboard/getFullMarket`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    try {
                        setEvents(response.data.resultData);
                        console.log(response.data)
                    } catch (e) {
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    useEffect(() => {
        if (userInfo) {
            getMypins();
        }
        else {
            navigate('/login');
        }
    }, [])
    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 ' >
                <div className='col-span-12'>
                    {events.length > 0 ?
                        <table className="w-full mt-[1rem]">
                            <thead className="#dddcd6 bg-[#dddcd6] ">
                                <tr>
                                    <th scope="col" className="text-[0.688rem] "></th>
                                    <th scope="col" className="text-[0.688rem] "></th>
                                    <th scope="col" className="text-[0.688rem] ">1</th>
                                    <th scope="col" className="text-[0.688rem] ">X</th>
                                    <th scope="col" className="text-[0.688rem] ">2</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                {
                                    events.map((event, index) => (
                                        <TableRow key={index} row={event} inplay={true} />
                                    ))
                                }
                            </tbody>
                        </table>
                        :
                        <div className='bg-[#fff] border-[1px] border-[solid] border-[#7e97a7] rounded-[10px] text-[#7e97a7] text-[1.25rem] mx-[9.5px] my-[25px] text-center p-[15px]'>
                            <h3 className='multimarket-heading mb-[.5rem] font-medium bg-none text-[1.5rem] text-[#7e97a7] border-b-[1px] border-b-[#e0e6e6] p-[10px] items-center leading-normal flex text-center justify-center'>There are currently no followed multi markets.</h3>
                            <p className='mb-[0rem] mt-0 text-[#7e97a7] text-[20px] text-center'>Please add some markets from events.</p>
                        </div>
                    }
                </div>
            </div >



            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative top-[56px]'>
                <div className='col-span-12'>
                    {/* <div className='bg-[#fff] border-[1px] border-[solid] border-[#7e97a7] rounded-[10px] text-[#7e97a7] text-[1.25rem] my-[25px] text-center p-[15px]'>
                        <h3 className='multimarket-heading mb-[.5rem] font-medium bg-none text-[1.5rem] text-[#7e97a7] border-b-[1px] border-b-[#e0e6e6] p-[10px] items-center leading-normal flex text-center justify-center'>There are currently no followed multi markets.</h3>
                        <p className='mb-[0rem] mt-0 text-[#7e97a7] text-[20px] text-center'>Please add some markets from events.</p>
                    </div> */}
                    <div className='mx-[10px] my-[22px] pt-[12px] px-[8px] pb-[23px] bg-[#fff] border-[1px] border-[solid] border-[#7e97a7] rounded-[10px] text-[#7e97a7] text-center'>
                        <h3 className='multimarket-heading mb-[.5rem] font-medium bg-none text-[1.4rem] text-[#7e97a7] border-b-[1px] border-b-[#e0e6e6] items-center leading-normal flex text-center justify-center'>There are currently no followed multi markets.</h3>
                        <p className='mb-[0rem] mt-0 text-[#7e97a7] text-[18px] text-center'>Please add some markets from events.</p>
                    </div>
                </div>

            </div >
        </>
    );
}

export default MultiMarket;
