import '../../App.css'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import '../../../src/styles.css';
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import CasinoOldResults from "components/casinoOldResults";
import { getCasinoResults } from "../../redux/slice/casinoResults/casinoResultsSlice";
import { getCasinoCards } from "../../redux/slice/casinoCards/casinoCardsSlice";
import SkyLoader from 'components/sky-loader';


export default function BOLLYWOOD_CASINO() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details
    const casioResults = useSelector((state) => state?.casinoResults?.casinoResults); // get casino results
    const casioCards = useSelector((state) => state?.casinoCards?.casinoCards); // get casino cards

    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    // console.log('casinoData', casinoData);
    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');
    const [selectedChip, setSelectedChip] = useState(null);
    const [chipStatus, setChipStatus] = useState(false);
    const [result, setResult] = useState(false);


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    // console.log('casinoStatus', casinoStatus);

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "1",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setCasinoPosition] = useState([])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])

    const preDefineData = [
        {
            "marketId": "9500101072749",
            "marketName": "WINNER",
            "min": 100,
            "max": 500000,
            "index": 0,
            "runners": [
                {
                    "selectionId": "30820189445",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 15
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 17
                            }
                        ]
                    }
                },
                {
                    "selectionId": "39846654321",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 999870,
                                "price": 5.15
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 5.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "30846565867",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 999800,
                                "price": 5.15
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 5.5
                            }
                        ]
                    }
                },
                {
                    "selectionId": "37421532158",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 7.65
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 8.35
                            }
                        ]
                    }
                },
                {
                    "selectionId": "30123448633",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.85
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 4.15
                            }
                        ]
                    }
                },
                {
                    "selectionId": "30998445781",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 5.15
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 5.5
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "30820189445": "A.Don",
                "39846654321": "B.Amar Akbar Anthony",
                "30846565867": "C.Sahib Bibi Aur Ghulam",
                "37421532158": "D.Dharam Veer",
                "30123448633": "E.Kis Kis Ko Pyaar Karoon",
                "30998445781": "F.Ghulam"
            }
        },
        {
            "marketId": "9500101072750",
            "marketName": "ODD",
            "min": 100,
            "max": 500000,
            "index": 1,
            "runners": [
                {
                    "selectionId": "846323355",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.32
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 1.36
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "846323355": "Odd"
            }
        },
        {
            "marketId": "9500101072751",
            "marketName": "DULHA DULHAN/BARATI",
            "min": 100,
            "max": 500000,
            "index": 2,
            "runners": [
                {
                    "selectionId": "854522122",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                },
                {
                    "selectionId": "987455545",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "854522122": "Dulha Dulhan K-Q",
                "987455545": "Barati J-A"
            }
        },
        {
            "marketId": "9500101072752",
            "marketName": "COLOR",
            "min": 100,
            "max": 500000,
            "index": 3,
            "runners": [
                {
                    "selectionId": "4160534237",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                },
                {
                    "selectionId": "4660534238",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "4160534237": "RED",
                "4660534238": "BLACK"
            }
        },
        {
            "marketId": "9500101072753",
            "marketName": "CARD",
            "min": 100,
            "max": 500000,
            "index": 4,
            "runners": [
                {
                    "selectionId": "4888834275",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.75
                            }
                        ]
                    }
                },
                {
                    "selectionId": "466672398",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.75
                            }
                        ]
                    }
                },
                {
                    "selectionId": "955612455",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.75
                            }
                        ]
                    }
                },
                {
                    "selectionId": "9894554525",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 3.75
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "466672398": "Q",
                "955612455": "K",
                "4888834275": "J",
                "9894554525": "A"
            }
        }
    ];


    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoResults({}))
        }
    }, [])
    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoCards({}))
        }
    }, [])

    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://ws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['bollywoodcasino'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['bollywoodcasino']) {
                    let casinoss = casinos?.data;

                    if (casinoss) {

                        setResult(false);

                        if (casinoss?.skyPlaceBet.length > 0) {

                            if (casinoss?.skyPlaceBet[0]?.data.status) {
                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                if (casinoss?.skyPlaceBet[0]?.data?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.data?.marketArr)) {

                                    setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);
                                }
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                    setShowBetsSlip(false);
                                }
                            }
                        } else {
                            setMarketState(preDefineData);
                            setCasinoStatus("SUSPEND");
                        }


                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)

                            }
                        }

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                setTimeout(() => {
                                    setResult(true);
                                    dispatch(getCasinoResults({}))
                                    dispatch(getCasinoCards({}))
                                  }, 4000)

                                let marketdata = casinoss?.skyPlaceBet[0]?.data?.marketArr;

                if (marketdata && marketdata.length > 0) {
                  marketdata.map((market_type) => {
                    var MId = market_type.marketId;
                    var runners = document.getElementsByClassName(
                      "runners_" + MId
                    );

                    for (var item of runners) {
                      var selecid = item.getAttribute("data-id");

                      var elements = document.getElementsByClassName(
                        `position_${MId}_${selecid}`
                      );

                      for (var el of elements) {
                        el.innerHTML = "";
                      }
                    }
                  });
                }
                            } else {
                                setWinnerName('');
                            }
                        }

                    }

                }



            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [userInfo])

    useEffect(() => {
        if(!showBetsSlip){
          cancelBetSlip();
        }
      }, [!showBetsSlip])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["bollywoodcasino"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            //   if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //       `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //       el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            //   } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            //   }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }


    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            // console.log({
            //     'selecid': selecid,
            //     'selectionId': selectionId,
            //     'market_name': market_name,
            //     'priceVal': priceVal,
            //     'stake': stake,
            //     'winloss': winloss,
            //     'isback': isback,
            // });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }


    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }

    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        // console.log({
        //     'event_id': event_id,
        //     'market_id': market_id,
        //     'is_back': is_back,
        //     'size': size,
        //     'is_fancy': is_fancy,
        //     'selection_id': selection_id,
        //     'runner_name': runner_name,
        //     'new_price': htmlId,
        //     'PM_FANCY': PM_FANCY,
        //     'market_name': market_name,
        // })

        // console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }

    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "BOLLYWOOD CASINO",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['bollywoodcasino'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)

                                if (response.data.result == 0) {
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                    cancelBetSlip();

                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            // console.log(error);
                            console.log(error.message)
                            setIsLoading(false);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                    cancelBetSlip();

                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };

    const handleClick = (value, status) => {
        setSelectedChip(value);
        setChipStatus(status)
        if (status) {
            console.log('value', value, status);
            placeStakeValue(value)
        }
    };


    return (
        <>
         {
        isLoading && <SkyLoader />
      }
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/other/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <CasinoVideo />
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>

                                <div className='flex flex-col'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>CARD</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.card : '0'}.png`}></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            winnerName &&
                            <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                    <span> {winnerName}</span>
                                </div>
                                <span className='absolute' >
                                    <canvas width="100vw" height="100vh">
                                    </canvas>
                                </span>
                            </div>
                        }


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid-cols-12'>

                            {
                                marketState?.length > 0 && marketState?.map((market, i) => (
                                    <div>
                                        <div className="card">
                                            <span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample1" role="button"
                                                aria-expanded="true" aria-controls="multiCollapseExample1"
                                                className="btn btn-primary card-header inner-sportbook">
                                                <span><strong>{market?.marketName}</strong></span>
                                                <span></span>
                                            </span>
                                        </div>

                                        <div className="col">
                                            <div className="show" id="multiCollapseExample9502012080813">
                                                <div className="main-bollywood">
                                                    {
                                                        market.runners.map((runner, j) => {
                                                            let runnerName = market.runnersName[runner.selectionId];
                                                            let price = runner?.price?.back[0]?.price || '-';
                                                            let imgName;
                                                            let layprice;

                                                            if (market?.marketName == "WINNER") {
                                                                runnerName = runnerName.split('.');
                                                                imgName = runnerName[0];
                                                                runnerName = runnerName[1];
                                                            }

                                                            if (market?.marketName == "WINNER" || market?.marketName == "ODD") {
                                                                layprice = runner?.price?.lay[0]?.price || '-';
                                                            }

                                                            const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended
                                                            return (
                                                                <>

                                                                    {
                                                                        (market?.marketName == "WINNER" || market?.marketName == "ODD" || market?.marketName == "DULHA DULHAN/BARATI") &&
                                                                        (
                                                                            <div className={`submain-bollywood`}>
                                                                                <div className="inner-bollywood">
                                                                                    <div className="span-image">
                                                                                        <span className='span-name'>

                                                                                            {
                                                                                                imgName == "A" ?
                                                                                                    <img src={`https://casino.gamaexchange.com/assets/bollywood/A-01.svg`} className="A-svg" />
                                                                                                    : imgName == "B" ?
                                                                                                        <img src={`https://casino.gamaexchange.com/assets/bollywood/B-01.svg`} className="A-svg" />
                                                                                                        : imgName == "C" ?
                                                                                                            <img src={`https://casino.gamaexchange.com/assets/bollywood/C-01.svg`} className="A-svg" />
                                                                                                            : imgName == "D" ?
                                                                                                                <img src={`https://casino.gamaexchange.com/assets/bollywood/D-01.svg`} className="A-svg" />
                                                                                                                : imgName == "E" ?
                                                                                                                    <img src={`https://casino.gamaexchange.com/assets/bollywood/E-01.svg`} className="A-svg" />
                                                                                                                    : imgName == "F" ?
                                                                                                                        <img src={`https://casino.gamaexchange.com/assets/bollywood/F-01.svg`} className="A-svg" />
                                                                                                                        :
                                                                                                                        <></>

                                                                                            }
                                                                                            {runnerName}
                                                                                            <div className={`text-center ml-[10px] align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className={`d-flex ${isSuspended ? 'suspended' : ''} runners_${market.marketId}`} data-id={runner.selectionId}>
                                                                                        <div appratehighlight="" className="back-1" onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                            casinoEventId,
                                                                                            casinoRoundId,
                                                                                            market.marketId,
                                                                                            true,
                                                                                            runner?.price?.back[0]?.size,
                                                                                            false,
                                                                                            runner.selectionId,
                                                                                            runnerName,
                                                                                            price,
                                                                                            false,
                                                                                            market.marketName
                                                                                        ) : null}>
                                                                                            <a className="d-flex flex-column p-0 align-items-center back" style={{ textDecoration: 'none', fontWeight: '600' }}> {price}
                                                                                                <span className=" justify-content-center" style={{ fontSize: '11px', fontWeight: '400' }}>{runner?.price?.back[0]?.size}</span>
                                                                                            </a>
                                                                                        </div>
                                                                                        {
                                                                                            market?.marketName == "WINNER" || market?.marketName == "ODD" ?
                                                                                                <div appratehighlight="" className="lay-1" onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                                    casinoEventId,
                                                                                                    casinoRoundId,
                                                                                                    market.marketId,
                                                                                                    false,
                                                                                                    runner?.price?.lay[0]?.size,
                                                                                                    false,
                                                                                                    runner.selectionId,
                                                                                                    runnerName,
                                                                                                    layprice,
                                                                                                    false,
                                                                                                    market.marketName
                                                                                                ) : null}>
                                                                                                    <a className="d-flex flex-column p-0 align-items-center lay" style={{ textDecoration: 'none', fontWeight: '600' }}> {layprice}
                                                                                                        <span className=" justify-content-center" style={{ fontSize: '11px', fontWeight: '400' }}>{runner?.price?.lay[0]?.size}</span>
                                                                                                    </a>
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                                {
                                                                                    showBetsSlip && market_id == market.marketId && selection_id == runner.selectionId && (
                                                                                        <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )

                                                                    }

                                                                </>

                                                            )
                                                        })
                                                    }


                                                </div>



                                                <div className='color-name'>
                                                    {
                                                        market.runners.map((runner, j) => {
                                                            let runnerName = market.runnersName[runner.selectionId];
                                                            let price = runner?.price?.back[0]?.price || '-';

                                                            const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended
                                                            return (
                                                                <>
                                                                    {
                                                                        market?.marketName == "COLOR" &&
                                                                        (

                                                                            <div className={`black-color ${isSuspended ? 'suspended' : ''} runners_${market.marketId}`} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                casinoEventId,
                                                                                casinoRoundId,
                                                                                market.marketId,
                                                                                true,
                                                                                runner?.price?.back[0]?.size,
                                                                                false,
                                                                                runner.selectionId,
                                                                                runnerName,
                                                                                price,
                                                                                false,
                                                                                market.marketName
                                                                            ) : null}>
                                                                                <span>{runnerName}</span>
                                                                                <div className={`text-center ml-[10px] align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>
                                                                            </div>


                                                                        )
                                                                    }
                                                                    {
                                                                        market?.marketName == "CARD" &&
                                                                        (
                                                                            <div className={`sub-card ${isSuspended ? 'suspended' : ''} runners_${market.marketId}`} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                casinoEventId,
                                                                                casinoRoundId,
                                                                                market.marketId,
                                                                                true,
                                                                                runner?.price?.back[0]?.size,
                                                                                false,
                                                                                runner.selectionId,
                                                                                runnerName,
                                                                                price,
                                                                                false,
                                                                                market.marketName
                                                                            ) : null}>
                                                                                {
                                                                                    runnerName == "Q" ?
                                                                                        <img src='https://casino.gamaexchange.com/assets/lucky7_cards/Q.png' className='card-image' />
                                                                                        : runnerName == "K" ?
                                                                                            <img src='https://casino.gamaexchange.com/assets/lucky7_cards/K.png' className='card-image' />
                                                                                            : runnerName == "J" ?
                                                                                                <img src='https://casino.gamaexchange.com/assets/lucky7_cards/J.png' className='card-image' />
                                                                                                : runnerName == "A" ?
                                                                                                    <img src='https://casino.gamaexchange.com/assets/lucky7_cards/A.png' className='card-image' />
                                                                                                    : <></>
                                                                                }

                                                                                <div className={`text-center ml-[10px] align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                            </div>
                                                                        )
                                                                    }


                                                                </>
                                                            )


                                                        })
                                                    }
                                                </div>
                                                {
                                                    (BetPlaceData.market_name == "COLOR" || BetPlaceData.market_name == "CARD") &&
                                                    showBetsSlip && market_id == market.marketId && (
                                                        <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        (casioResults && casioResults?.bollywoodCasinoResultDeclared) &&
                        <CasinoOldResults event_type={Appconfig.casino_type_id["bollywoodcasino"]} results={casioResults?.bollywoodCasinoResultDeclared} cards={casioCards.bollywoodCasinoCardDeclared} />
                    }
                </div >
            }
        </>
    )
}
// export default Casino;
