// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardBanner } from '../../../services/users';

// Async Thunks for API calls
export const getBanner = createAsyncThunk('/banner/getDashboardBanner', async (params, { rejectWithValue, dispatch }) => {
    try {
        const response = await getDashboardBanner(params);
        return response.data;
    } catch (error) {
        // const errorMessage = error.response.data.message || 'something went wrong';
        return rejectWithValue(error.response.data);
    }
});


const bannerSlice = createSlice({
    name: 'banners',
    initialState: {
        banners: [],
    },
    reducers: {
        setBanners(state, action) {
            state.banners = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBanner.pending, (state) => {
                state.banners = [];
            })
            .addCase(getBanner.fulfilled, (state, action) => {
                state.banners = action.payload.resultData;
            })
            .addCase(getBanner.rejected, (state, action) => {
                state.banners = []
            })
    },
});

export const { setBanners } = bannerSlice.actions;


export default bannerSlice.reducer;
