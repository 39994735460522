import '../../App.css'
import { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import { useSelector } from 'react-redux';
import CasinoOldResults from "components/casinoOldResults";
import { getCasinoResults } from "../../redux/slice/casinoResults/casinoResultsSlice";
import { getCasinoCards } from "../../redux/slice/casinoCards/casinoCardsSlice";
import SkyLoader from 'components/sky-loader';


export default function TEENPATTI1DAY() {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details
    const casioResults = useSelector((state) => state?.casinoResults?.casinoResults); // get casino results
    const casioCards = useSelector((state) => state?.casinoCards?.casinoCards); // get casino cards

    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');



    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [result, setResult] = useState(false);

    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')

    const [runnerName, setRunnerName] = useState({
        "30820190609": "PLAYER A",
        "30820190610": "PLAYER B"
    })



    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });

    // console.log('BetPlaceData',BetPlaceData);
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setCasinoPosition] = useState([])

    const showModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(15);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(15);
        }
    }, [seconds]);

    const preDefineData = [
        {
            "runners": [
                {
                    "price": {
                        "back": [
                            {
                                "price": 1.65,
                                "size": 500000
                            },
                            {
                                "price": 1.64
                            },
                            {
                                "price": 1.63
                            }
                        ],
                        "lay": [
                            {
                                "price": 1.68,
                                "size": 500000
                            },
                            {
                                "price": 1.69
                            },
                            {
                                "price": 1.7
                            }
                        ]
                    },
                    "status": "SUSPEND",
                    "selectionId": "30820190609"
                },
                {
                    "price": {
                        "back": [
                            {
                                "price": 2.46,
                                "size": 500000
                            },
                            {
                                "price": 2.44
                            },
                            {
                                "price": 2.42
                            }
                        ],
                        "lay": [
                            {
                                "price": 2.52,
                                "size": 500000
                            },
                            {
                                "price": 2.56
                            },
                            {
                                "price": 2.58
                            }
                        ]
                    },
                    "status": "SUSPEND",
                    "selectionId": "30820190610"
                }
            ],
            "marketId": "9132410080547",
            "marketName": "WINNER",
            "min": 100,
            "max": 500000,
            "runnersName": {
                "30820190609": "PLAYER A",
                "30820190610": "PLAYER B"
            }
        }
    ];

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])


    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoResults({}))
        }
    }, [])
    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoCards({}))
        }
    }, [])


    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://ws.operator.buzz:9093/");
        if (casinowebsocket) {

            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['1daytp'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };


            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['1daytp']) {
                    let casinoss = casinos?.data;
                    console.log('casinoss',JSON.stringify(casinoss));

                    if (casinoss) {

                        setResult(false);

                        if (casinoss?.skyPlaceBet.length > 0) {

                            if (casinoss?.skyPlaceBet[0]?.data?.status == "ONLINE" || casinoss?.skyPlaceBet[0]?.status == "ONLINE") {

                                if (casinoss?.skyPlaceBet[0]?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.marketArr)) {
                                    // let allMarket = casinoss?.skyPlaceBet[0]?.marketArr;
                                    // if (allMarket.length > 0) {
                                    //     allMarket?.map(market => {
                                    //         market.hasOwnProperty("runners") && market.runners.map((runner, index) => {
                                    //             console.log('market',market);

                                    //             console.log('runner',runner);
                                    //             const backElements = document.getElementsByClassName('availableToBack_' + market?.marketId + '_' + runner?.selectionId);
                                    //             if (backElements.length > 0) {
                                    //                 backElements[0].innerHTML = runner?.price?.back[0]?.price;
                                    //             }

                                    //             const layElements = document.getElementsByClassName('availableToLay_' + market?.marketId + '_' + runner?.selectionId);
                                    //             if (layElements.length > 0) {
                                    //                 layElements[0].innerHTML = runner?.price?.lay[0]?.price;
                                    //             }
                                    //         })
                                    //     })
                                    // }

                                    setMarketState(casinoss?.skyPlaceBet[0]?.marketArr);
                                }

                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.status);
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                            }
                        } else {
                            setMarketState(preDefineData);
                            setCasinoStatus("SUSPEND");
                        }

                        if (casinoss?.skySuspend) {
                            if (casinoss?.skySuspend[0]?.data?.status == "SUSPEND" || casinoss?.skySuspend[0]?.status == "SUSPEND") {
                                if (casinoss?.skySuspend[0]?.data) {

                                    setCasinoStatus(casinoss?.skySuspend[0]?.data?.status);

                                } else {
                                    setCasinoStatus(casinoss?.skySuspend[0]?.status);

                                }
                                setShowBetsSlip(false);

                            }
                        }


                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)
                            }
                        }

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                setTimeout(() => {
                                    setResult(true);
                                    dispatch(getCasinoResults({}))
                                    dispatch(getCasinoCards({}))
                                }, 4000)
                                let marketdata = casinoss?.skyPlaceBet[0]?.marketArr;

                                if (marketdata && marketdata.length > 0) {
                                    marketdata.map((market_type) => {
                                        var MId = market_type.marketId;
                                        var runners = document.getElementsByClassName(
                                            "runners_" + MId
                                        );

                                        for (var item of runners) {
                                            var selecid = item.getAttribute("data-id");

                                            var elements = document.getElementsByClassName(
                                                `position_${MId}_${selecid}`
                                            );

                                            for (var el of elements) {
                                                el.innerHTML = "";
                                            }
                                        }
                                    });
                                }

                            } else {
                                setWinnerName('');
                            }
                        }

                    }

                }

            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            // getBalance();
            getAuraCasinoPosition();
        }
    }, [userInfo])

    useEffect(() => {
        if (!showBetsSlip) {
            cancelBetSlip();
        }
    }, [!showBetsSlip])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["1daytp"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            // if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //         `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //         el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //         el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            // } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            // }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }

    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            console.log({
                'selecid': selecid,
                'selectionId': selectionId,
                'market_name': market_name,
                'priceVal': priceVal,
                'stake': stake,
                'winloss': winloss,
                'isback': isback,
            });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }

    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        console.log({
            'event_id': event_id,
            'market_id': market_id,
            'is_back': is_back,
            'size': size,
            'is_fancy': is_fancy,
            'selection_id': selection_id,
            'runner_name': runner_name,
            'new_price': htmlId,
            'PM_FANCY': PM_FANCY,
            'market_name': market_name,
        })

        console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }


    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "1 Day Teenpaati",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['1daytp'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                    cancelBetSlip();
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            // console.log(error);
                            console.log(error.message)
                            setIsLoading(false);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                    cancelBetSlip();
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min ? market?.min + ' - ' + market?.max : '100 - 500000'}
            </span>
        );
    };


    return (
        <>
            {
                isLoading && <SkyLoader />
            }
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/other/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>

                        <CasinoVideo />

                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>

                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>
                                <div className='flex flex-col'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER A</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_A?.card_1 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_A?.card_2 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_A?.card_3 : '0'}.png`}></img>
                                    </div>
                                </div>
                                <div className='flex flex-col pt-[3px]'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER B</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_B?.card_1 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_B?.card_2 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER_B?.card_3 : '0'}.png`}></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            winnerName &&
                            <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                    <span> {winnerName}</span>
                                </div>
                                <span className='absolute' >
                                    <canvas width="100vw" height="100vh">
                                    </canvas>
                                </span>
                            </div>
                        }


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid grid-cols-12'>

                            {
                                marketState?.map((market, i) => (
                                    <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                        <span className='px-[5px] py-[2px] text-[12px] font-black bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                            <span className='text-[.75rem] font-black text-[#ffffff]'>{market?.marketName ? market?.marketName : 'WINNER'}</span>
                                            <span className='block lg:hidden cursor-pointer'>
                                                <Popover
                                                    placement="left"
                                                    content={() => <CustomContent market={market} />}
                                                    trigger="click"
                                                >
                                                    <i className="fa fa-info-circle ml-2"></i>
                                                </Popover>

                                            </span>

                                        </span>
                                        <div className="flex-[1_0_0%]">
                                            <div className="" id="multiCollapseExample9102906131109">
                                                <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">

                                                    <table className='w-full relative mb-0 [caption-side:bottom] border-collapse text-[.75rem] text-[#212529] align-top border-[#dee2e6]'>
                                                        <thead className='align-bottom text-[.75rem]'>
                                                            <th className='border-b-[0px] p-[5px] text-center block'>
                                                                <dl className='p-0 m-0 h-[20px] flex text-[0.625rem] leading-[7px] rounded-[3px] border-b-[1px] items-center justify-center bg-[#bed5d8]'>
                                                                    <dt className='inline-block mr-[8px] text-[#315195] font-bold text-[.625rem] leading-[7px] text-center'>Min/Max</dt>
                                                                    <dd className='inline-block mb-0 text-[#000000] ml-0 text-[.625rem] text-center leading-[7px]'>{market?.min ? market?.min + ' - ' + market?.max : '100 - 500000'} </dd>
                                                                </dl>
                                                            </th>
                                                            <th className='rounded-tl-[0px] border-b-[1px] border-[solid] border-b-[#7e97a7] p-[5px] border-t-[none] text-center min-w-[75px] bg-[#72bbef] [border-left-color:#fff]'>Back</th>
                                                            <th className='rounded-tr-[0px] border-b-[1px] border-[solid] border-b-[#7e97a7] p-[5px] border-t-[none] text-center min-w-[75px] bg-[#faa9ba] [border-left-color:#fff]'>Lay</th>
                                                        </thead>
                                                        <tbody className={`border-t-[1px] border-t-[solid] border-t-[#7e97a7] [vertical-align:inherit] text-[.75rem] [word-wrap:break-word]`}>
                                                            {
                                                                market?.runners?.map((runner, i) => {
                                                                    const { price } = runner;
                                                                    const backPrice = price?.back?.[0]?.price || '0';
                                                                    const layPrice = price?.lay?.[0]?.price || '0';

                                                                    return (
                                                                        <tr className={`relative border-b-[1px]  border-b-[#7e97a7] odd_even_clr bets-selections runners_${market?.marketId}`} data-id={runner?.selectionId} >
                                                                            <td className='text-left pl-[6px] align-middle text-[.75rem] px-[0] py-[1.5px] font-bold border-t-[none] bg-transparent border-b-[1px] border-b-[#7e97a7] [box-shadow:inset_0_0_0_9999px_transparent]'>
                                                                                <span id="runnerName" className='text-[0.813rem] block !font-bold !text-[#23282c] text-left'> {market?.runnersName ? market?.runnersName[runner?.selectionId] : runnerName[runner?.selectionId]}</span>
                                                                                <span className='font-normal text-[0.688rem] flex text-left'>
                                                                                    <span className='block font-normal text-[0.688rem] text-left'>
                                                                                        <span className='flex p-0 font-normal text-[0.688rem] text-left'>
                                                                                            <span className='mr-[5px] text-[0.688rem] !text-[#228b22] !font-bold !flex text-left flex items-center gap-x-1'>
                                                                                                <span id='' className={`win market-exposure block text-[0.688rem]  !font-bold text-left ml-[5px] mr-[3px] position_${market?.marketId}_${runner?.selectionId} selection_position_${runner.selectionId}`} ></span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </td>
                                                                            <td id="back_1" className={`w-[10%] align-middle text-center text-[.75rem] px-[0]  font-bold border-t-[none] relative bg-[#72bbef] [border-left-color:#fff] `}>
                                                                                <button className={`back-1 w-[100%] h-[100%] [color:inherit] cursor-pointer text-center text-[.75rem] px-[0] py-[1.5px] font-bold`}
                                                                                    onClick={() =>
                                                                                        handleOpenBetSlip(
                                                                                            casinoEventId,
                                                                                            casinoRoundId,
                                                                                            market?.marketId,
                                                                                            true,
                                                                                            50000,
                                                                                            false,
                                                                                            runner.selectionId,
                                                                                            market?.runnersName[runner?.selectionId],
                                                                                            backPrice,
                                                                                            false,
                                                                                            market?.marketName
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span className={`backprice-match font-bold block min-w-[50px] !text-[.75rem] !relative [color:inherit] availableToBack_${market?.marketId}_${runner?.selectionId}`} > {backPrice}</span>
                                                                                    <span className='block font-normal text-[0.688rem] min-w-[50px] [color:inherit]'>50000</span>
                                                                                </button>
                                                                            </td>
                                                                            <td id="lay_1" className={`w-[10%] align-middle text-center text-[.75rem] px-[0] font-bold border-t-[none] relative bg-[#faa9ba] [border-left-color:#fff]`}>
                                                                                <button className={`lay-1  w-[100%] h-[100%] [color:inherit] cursor-pointer text-center text-[.75rem] px-[0] py-[1.5px] font-bold`}
                                                                                    // fullmarketodds={parseFloat(runner.lay_1_price)}
                                                                                    onClick={(e) => {
                                                                                        handleOpenBetSlip(
                                                                                            casinoEventId,
                                                                                            casinoRoundId,
                                                                                            market?.marketId,
                                                                                            true,
                                                                                            50000,
                                                                                            false,
                                                                                            runner?.selectionId,
                                                                                            market?.runnersName[runner?.selectionId],
                                                                                            layPrice,
                                                                                            false,
                                                                                            market?.marketName
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <span className={`layprice-match font-bold block min-w-[50px] !text-[.75rem] !relative [color:inherit] availableToLay_${market?.marketId}_${runner?.selectionId}`}> {layPrice}</span>
                                                                                    <span className='block font-normal text-[0.688rem] min-w-[50px] [color:inherit]'>50000</span>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>

                                                    {
                                                        casinoStatus == "SUSPEND" &&
                                                        <div className="suspended-casino">
                                                            <span className="stats-text">SUSPENDED</span>
                                                        </div>
                                                    }


                                                    {showBetsSlip && market_id == market.marketId && (
                                                        <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }

                            {
                                (casioResults && casioResults?.sky1DayTeenResultDeclared) &&
                                <CasinoOldResults event_type={Appconfig.casino_type_id["1daytp"]} results={casioResults?.sky1DayTeenResultDeclared} cards={casioCards.sky1DayTeenCardDeclared} />
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
