import '../../App.css'
import { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import axios from 'axios';
import Appconfig from '../../config/config'
import { useSelector, useDispatch } from 'react-redux';
import { getAllBets } from '../../redux/slice/openBet/openBetSlice';
// import { WebSocketContext } from '../../context/websocket';


function RightSidebar() {
    // const websocket = useContext(WebSocketContext);

    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details
    const userInfos = useSelector((state) => state.eventData); // Use selector to access state


    const [isIframe, setIsIframe] = useState(false);
    const [showLtv, setShowLtv] = useState(false);
    const [scoreUrl, setScoreUrl] = useState("");
    const [tvUrl, setTvUrl] = useState();
    let { event_id } = useParams();
    const location = useLocation();
    if (!event_id) {
        event_id = location?.pathname?.split('/')[2];
    }


    const [eventsName, setEventsName] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const dispatch = useDispatch();

    const allBets = useSelector((state) => state.bets); // Use selector to access state
    const backBets = allBets?.bets?.backBets?.length > 0 ? allBets?.bets?.backBets : [];
    const layBets = allBets?.bets?.layBets?.length > 0 ? allBets?.bets?.layBets : [];


    useEffect(() => {
        if (userInfos.eventData[event_id]) {
            setTvUrl(userInfos.eventData[event_id][0]?.tv_link);
        }
    }, [userInfos.eventData[event_id]])


    // useEffect(() => {
    //     if (websocket) {
    //         websocket.onmessage = (evt) => {
    //             const data = JSON.parse(evt.data);

    //             if (data.action == "USER_BET_PLACE") {
    //                 let market = data.data;
    //                 console.log("Bet Details SOCKET RESPONDING");
    //                 if (market) {
    //                     getOpenBetsByEvent(event_id)
    //                 }
    //             }

    //         }
    //     }
    // }, [websocket, event_id])


    // useEffect(() => {
    //     getEvents();
    // }, [])

    // const getEvents = async () => {
    //     const eventId = event_id;
    //     var data;
    //     let url;
    //     if (userInfo) {
    //         url = "getDashboardDataByeventId"
    //         data = JSON.stringify({
    //             user_id: userInfo._id,
    //             event_id: eventId,
    //         });
    //     } else {
    //         url = "getDashboardDataByEventIdWithoutUserID"
    //         data = JSON.stringify({
    //             event_id: eventId,
    //         });
    //     }
    //     const config = {
    //         method: "post",
    //         url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     };

    //     try {
    //         const response = await axios(config);
    //         if (response.data.result == 1) {
    //             setTvUrl(response.data?.resultData[0]?.tv_link);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    useEffect(() => {
        if (userInfo) {
            getOpenBetEvents();
        }
    }, [userInfo])

    const getOpenBetEvents = async () => {

        var data = JSON.stringify({
            user_id: userInfo?._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}reports/auraOpenBetEvents`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    setEventsName(response.data.resultData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const getOpenBetsByEvent = async (matchId) => {
        dispatch(getAllBets({
            user_id: userInfo?._id,
            match_id: matchId
        }))
    }


    useEffect(() => {
        getScoreUrl(event_id)
    }, [event_id])

    function getScoreUrl(eventId) {

        var data = JSON.stringify({
            event_id: eventId,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getScore`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    // alert("Hello");
                    setScoreUrl(response.data.resultData.animation);
                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
        // }
    }


    return (
        <div className='bg-[linear-gradient(-180deg,_#FFFFFF_0%,_#FFFFFF_100%)] w-full max-w-[25vw] fixed hover:overflow-auto' style={{ height: 'calc(100vh - 6.25rem)' }}>
            <div className='grid grid-cols-12'>
                {
                    location.pathname.startsWith('/fullMarket') &&
                    <div className='flex justify-between col-span-12 items-center px-[7px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] mb-1 cursor-pointer'>
                        <div onClick={() => setIsIframe(!isIframe)} className='flex text-white text-xs leading-[25px] font-extrabold'>
                            Live Streaming
                        </div>
                    </div>
                }

                {
                    (location.pathname.startsWith('/fullMarket') && isIframe) ?
                        <>
                            <div id="streamingBox" className="tv-fix">
                                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" contenteditable="false" frameborder="0" scrolling="yes" id="tvframe"
                                    // onLoad={() => setShowLtv(true)} 
                                    referrerpolicy="no-referrer" src={tvUrl} style={{ height: "14rem", display: isIframe ? "" : "none" }}></iframe>
                            </div>
                            <div className=" col-span-12 cricket-banner xp-0">
                                <div className="score-wrapper border-t-[5px]  border-t-[#c13333]" style={{ display: isIframe ? 'block' : 'none', marginTop: userInfo ? '0px' : '18px', backgroundColor: "#000", height: "210px" }}>
                                    <iframe frameBorder="0" className="score-board w-full" src={scoreUrl} title="match-score" style={{ height: '200px' }} />
                                </div>
                            </div></>
                        :
                        <></>
                }

                <div className='flex justify-between col-span-12 items-center px-[7px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] cursor-pointer'>
                    <div className='flex text-white text-xs leading-[25px] font-extrabold'>
                        Open Bets
                    </div>
                </div>


                <div className='col-span-12 mb-2 mt-2 p-1'>
                    <select className='cursor-pointer p-[3px] border-[1px] border-[#000000] w-full rounded-[5px]' onChange={(e) => getOpenBetsByEvent(e.target.value)}>
                        <option>Select Market</option>
                        {
                            eventsName?.map(events => {
                                return (
                                    <option value={events.match_id}>{events.event_name}</option>

                                )
                            })
                        }
                    </select>
                </div>

                <div className='col-span-12'>

                    {
                        allBets?.bets ?
                            <>
                                <div className='p-1 text-[#000000] font-semibold text-base bg-[#ced5da] mb-[2px]'>
                                    Matched</div>

                            </>
                            :
                            <></>
                    }

                    <div className='p-1'>
                        {backBets.length > 0 && (
                            <>
                                <div className='grid grid-cols-12 p-1 '>
                                    <div className='col-span-5 text-[11px]'>
                                        Back (Bet For)
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Odds
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Stake
                                    </div>
                                    <div className='col-span-3 text-[11px]'>
                                        Profit
                                    </div>
                                </div>
                                {backBets.map(data => (
                                    <>
                                        {
                                            isChecked &&
                                            <div className='text-[11px] p-[2px] bg-[#d4e8f8]'>
                                                Ref : {new Date(data.createdAt).toLocaleString()}
                                            </div>
                                        }

                                        <div key={data.id} className='grid grid-cols-12 gap-2 pl-1 pt-3 pb-3 bg-[#beddf4] border-b-[1px] border-b-[#67afe5]'>
                                            <div className='col-span-5 flex gap-2 justify-evenly'>
                                                <div className='text-[11px] bg-[#7dbbe9] p-1 rounded-[3px]'>Back</div>
                                                <div className='text-[11px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap block'>
                                                    {data.place_name}
                                                </div>

                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.price_val}
                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.stake}
                                            </div>
                                            <div className='col-span-3 text-[11px]'>
                                                {data.profit.toFixed(0)} (-{data.loss.toFixed(0)})
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </>
                        )}

                        {layBets.length > 0 && (
                            <>
                                <div className='grid grid-cols-12 p-1'>
                                    <div className='col-span-5 text-[11px]'>
                                        Lay (Bet Against)
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Odds
                                    </div>
                                    <div className='col-span-2 text-[11px]'>
                                        Stake
                                    </div>
                                    <div className='col-span-3 text-[11px]'>
                                        Liability
                                    </div>
                                </div>
                                {layBets.map(data => (
                                    <>
                                        {
                                            isChecked &&
                                            <div className='text-[11px] p-[2px] bg-[#f7e7eb]'>
                                                Ref : {new Date(data.createdAt).toLocaleString()}
                                            </div>
                                        }
                                        <div key={data.id} className='grid grid-cols-12 gap-2 pt-3 pb-3 pl-1 bg-[#f3dce2] border-b-[1px] border-b-[#dfa3b3]'>
                                            <div className='col-span-5 flex gap-2 justify-evenly'>
                                                <div className='text-[11px] bg-[#dfa3b3] p-1 rounded-[3px]'>Lay</div>
                                                <div className='text-[11px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap block'>
                                                    {data.place_name}
                                                </div>

                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.price_val}
                                            </div>
                                            <div className='col-span-2 text-[11px]'>
                                                {data.stake}
                                            </div>
                                            <div className='col-span-3 text-[11px]'>
                                                {data.loss.toFixed(0)}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </>
                        )}
                    </div>
                    {
                        allBets?.bets &&
                        <div className='p-2 text-[14px]'>
                            <Checkbox onChange={() => setIsChecked(!isChecked)}>Bet Info</Checkbox>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}


export default RightSidebar;
