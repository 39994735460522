import '../../App.css'
import { useEffect, useState } from 'react';
import Appconfig from '../../config/config'
import {  useNavigate } from 'react-router-dom';
import {  NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useDispatch,useSelector } from 'react-redux';

const Setting = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata); // get login user details

    const [chipList, setchipList] = useState([]);

    useEffect(() => {
        getChips();
    }, [])

    const getChips = async () => {
        var data = JSON.stringify({
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    // let tmpChipList = response.data;
                    // const newChipList = tmpChipList.map((chipList, index) => {
                    //     tmpChipList[index]["id"] = index + 1;
                    // });
                    // setchipList(tmpChipList);
                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                // postErrorToslack(error.message);
                console.log(error);
            });
    }


    const handleInputChange = (event) => {
        let index = event.target.getAttribute('data-id');
        console.clear();
        console.log('index', index)
        // const newChipList = chipList.map((chip, index) => {
        //     if(index == tempIndex)
        //     {
        //         console.log('chipList1',chip);
        //         chipList[index]["chip_value"] =  parseFloat(event.target.value)
        //     }
        // });
        console.log('before', chipList[index])
        chipList[index]["chip_value"] = parseFloat(event.target.value == '' ? 0 : event.target.value);
        console.log('after', chipList[index])
        setchipList([
            ...chipList,
        ]);
        console.log('chipList', chipList);
    }

    function updateChips(chipList) {
        let newdata = [];
        for (var i = 0; i < chipList.length; i++) {
            newdata[i] = { "_id": chipList[i]._id, "chip_value": document.getElementById('stakeEdit_8' + chipList[i]._id).value };
        }
        var data = JSON.stringify({
            "data": newdata
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/updateMultipleChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.ok) {
                        NotificationManager.success('Stake Update Successfully!', '', 3000);
                        getChips();
                        setTimeout(() => {
                            navigate('/');
                        }, 2000)

                    }
                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                // postErrorToslack(error.message);
                console.log(error);
            });
    }

    return (
        <>
            <div className='grid lg:hidden grid-col-12 relative top-[56px]'>
                <div className='col-span-12'>
                    <h3 className='!pl-[5px] bg-[linear-gradient(-180deg,_#A4DC60_0%,_#4F9F21_100%)] text-[#ffffff] text-[1rem] leading-[2.2] text-center m-0 flex items-center flex-row justify-start font-medium'>
                        <svg className='text-[#000000]' width="20" height="20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <path d="M10.488 13c.621 0 1.203-.16 1.744-.478a3.608 3.608 0 0 0 1.29-1.278A3.32 3.32 0 0 0 14 9.512c0-.621-.16-1.203-.478-1.744a3.578 3.578 0 0 0-1.29-1.29A3.382 3.382 0 0 0 10.488 6a3.32 3.32 0 0 0-1.732.478c-.534.318-.96.748-1.278 1.29A3.382 3.382 0 0 0 7 9.512c0 .621.16 1.198.478 1.732.318.534.744.96 1.278 1.278a3.32 3.32 0 0 0 1.732.478zm7.187-2.014l2.147 1.634c.099.08.157.184.173.313a.555.555 0 0 1-.074.36l-2.073 3.462a.448.448 0 0 1-.26.216.56.56 0 0 1-.357-.024l-2.542-.985c-.658.464-1.242.793-1.752.985l-.37 2.62a.607.607 0 0 1-.185.313.453.453 0 0 1-.309.12H7.927a.485.485 0 0 1-.469-.433l-.395-2.62c-.69-.272-1.267-.6-1.727-.985l-2.567.985c-.263.112-.469.048-.617-.192L.079 13.293a.555.555 0 0 1-.074-.36.464.464 0 0 1 .173-.313l2.171-1.634A7.323 7.323 0 0 1 2.3 10c0-.433.017-.761.05-.986L.177 7.38a.464.464 0 0 1-.173-.313.555.555 0 0 1 .074-.36l2.073-3.462c.148-.24.354-.304.617-.192l2.567.985a8.02 8.02 0 0 1 1.727-.985l.395-2.62A.485.485 0 0 1 7.927 0h4.146c.115 0 .218.04.309.12.09.08.152.184.185.313l.37 2.62c.641.24 1.226.569 1.752.985l2.542-.985a.56.56 0 0 1 .358-.024.448.448 0 0 1 .259.216l2.073 3.462c.066.112.09.232.074.36a.464.464 0 0 1-.173.313l-2.147 1.634c.033.225.05.553.05.986 0 .433-.017.761-.05.986z" fill="currentColor"></path>
                        </svg>
                        <span className='text-[#000000] font-semibold'>&nbsp;&nbsp;Setting</span>
                    </h3>
                    <h3 className=' bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] text-[1rem] leading-[2.2] text-center m-0 flex items-center flex-row justify-start font-medium'>
                        <span >&nbsp;&nbsp;Stake</span>
                    </h3>

                    <div className='mt-[1rem]'>
                        <span className='text-[#000000] font-semibold pl-[5px]'>Quick Stakes</span>

                        <div className="grid grid-cols-12 mt-[10px] relative !pb-0 !pt-0 !p-[.5rem] flex flex-wrap">
                            {
                                chipList?.map((row, index) => {
                                    return (
                                        <div className="col-span-3 !p-[.25rem] flex-[1_0_0%] w-full max-w-full mb-2">
                                            <input className="bg-[#f9f9f9] border-[2px] [border-image:conic-gradient(#6ca7ec,_#2222b9,_#6ca7ec,_#2222b9,_#6ca7ec,_#2222b9,_#6ca7ec)_1]
                                             !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[40px] !ml-[0] !mr-[5px] !my-[0] text-center w-full" id={`stakeEdit_8${row._id}`} type='text' pattern="[0-9]*" data-id={index} value={row.chip_value} onChange={handleInputChange} />
                                        </div>
                                    )
                                })
                            }


                        </div>

                        {
                            chipList.length > 0 &&
                            <div onClick={() => updateChips(chipList)} className="col-span-12 mt-5 !pb-0 !p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0 text-center text-[.75rem] font-bold">
                                <button className="!bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] text-[1rem]  px-[0.75rem] py-[0.375rem] text-[#ffffff] border-[#000000]  w-full border-[1px] border-[#000000] rounded-[5px] font-bold">Save</button>
                            </div>

                        }


                    </div>

                </div>
            </div>
        </>
    )

}

export default Setting